import React,  { useState } from 'react';
import './App.css';

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const togglePanel = () => {
    setIsOpen(!isOpen);
    // Toggle body overflow
    if (!isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  };

  const images = [
    'https://img.ssensemedia.com/images/g_center,f_auto/242828M185004_1/stone-island-veste-teinte-en-plongee-noire.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/242545M216043_1/skims-ensemble-de-trois-boxers-noirs-skims-stretch.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/241513M234048_1/sandales-222-west-noires-1774.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/241782M231001_1/mules-the-california-blanc-casse.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/231828M176023_1/manteau-coupe-vent-beige.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/241482M219012_1/tekla-black-hooded-bathrobe.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/231376M159000_1/black-monogram-umbrella.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/241802F050000_1/the-north-face-black-base-camp-xs-duffle-bag.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/232167M133019_1/gold-m3105-d-glasses.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/241566M213000_1/ssense-xx-t-shirt.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/241331M184000_1/luu-dan-black-croc-faux-leather-trench-coat.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/241085M216005_1/boss-three-pack-black-boxers.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/241085M220003_1/boss-six-pack-black-ribbed-short-socks.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/241402M237052_1/new-balance-ssense-xx-green-and-brown-1906r-sneakers.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/241566M139000_1/ssense-xx-cap.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/241566M202000_1/ssense-xx-hoodie.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/222175M180023_1/black-flight-bomber.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/231011M213011_1/black-mock-neck-long-sleeve-t-shirt.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/222099M186005_1/black-512-slim-taper-jeans.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/222895M840003_1/assouline-louis-vuitton-manufactures.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/231607M145002_1/silver-paper-clip-necklace.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/222342M139008_1/white-bb-different-cap.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/222376M176000_1/green-braidley-coat.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/222342M213054_1/black-be-different-t-shirt.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/222895M800001_1/assouline-louis-vuitton-virgil-abloh--classic-balloon-cover.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/222342M202049_1/off-white-distressed-hoodie.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/221376M138003_1/beige-cashmere-logo-beanie.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/212011M193014_1/black-dri-fit-pro-flex-vent-max-shorts.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/221099M186028_1/blue-501-original-jeans.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/222828M213001_1/stone-island-black-patch-long-sleeve-t-shirt.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/222402M237206_1/gray-made-in-uk-991-low-top-sneakers.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/221161M180009_1/fear-of-god-essentials-black-pullover-jacket.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/221559M166007_1/black-isar-backpack.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/221161M193019_1/tan-cotton-shorts.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/221011M237196_1/burgundy-acronym-edition-blazer-low-sneakers.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/221011M237197_1/black-acronym-edition-blazer-low-sneakers.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/212802M231076_1/black-thermoball-traction-loafers.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/212161M190049_1/ssense-exclusive-beige-fleece-lounge-pants.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/212161M205002_1/ssense-exclusive-beige-mock-neck-sweatshirt.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/212445M140004_1/beige-wool-mountain-metro-hat.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/212445M140005_1/khaki-mountain-metro-hat.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/212776M150000_1/black-knit-buckle-scarf.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/212676M176001_1/black-wool-and-cashmere-cape-coat.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/212451M193003_1/brown-jersey-mini-gg-shorts.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/211857M191006_1/black-zip-vent-trousers.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/211451M140236_1/reversible-brown-canvas-gg-bucket-hat.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/202451M171258_1/black-gg-embossed-tennis-pouch.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/211695M148130_1/brown-embroidered-logo-bear.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/211451M148186_1/silver-marmont-keychain.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/211729M150032_1/black-pleats-tube-neck-warmer.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/211828M188017_1/black-tco-old-cargo-pants.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/211451M237099_1/beige-and-brown-tennis-1977-slip-on-sneakers.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/211451M192020_1/black-denim-eco-washed-shirt.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/211451M200039_1/tan-wool-web-cardigan.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/202451M234078_1/beige-gg-crisscross-sideline-sandals.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/202451M170248_1/black-gg-tennis-messenger-bag.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/202828M138019_1/grey-wool-beanie.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/192451M202020_1/black-tennis-club-hoodie.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/201451M192101_1/black-silk-crepe-de-chine-gg-shirt.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/192451M213007_1/white-gg-tennis-club-t-shirt.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/201451M140192_1/black-gg-supreme-bucket-hat.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/191451M150011_1/brown-gg-poncho.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/201451M164149_1/beige-wool-gg-wallet.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/201451M138212_1/beige-wool-gg-beanie.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/201451M150220_1/beige-wool-gg-scarf.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/201161M202022_1/black-polar-fleece-sweater.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/201828M201199_1/black-cotton-sweatshirt.jpg',
    'https://img.ssensemedia.com/images/g_center,f_auto/192828M139004_1/black-wool-baseball-cap.jpg',
  ];

  return (
    <div className='max-w-7xl mx-auto'>
      <div className='gap-4 px-5 mt-20'>
        {/* <div>
          <p className="font-normal">MY WARDROBE</p>
        </div> */}
        <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5'>
          {images.map((imageUrl, index) => (
            <div key={index} className="aspect-w-1 aspect-h-1 flex flex-col justify-end items-center p-4">
              <img 
                className="object-contain justify-end max-h-96"
                src={imageUrl} 
                alt=''
                draggable={false}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="fixed inset-x-0 top-0 mt-3 mx-3 lg:mx-12 md:grid md:grid-cols-3">
        <div className="items-center space-x-6 justify-start hidden md:flex">
          <a target='_blank' rel="noreferrer" href='https://www.ssense.com/' className="hover:underline focus:underline font-light">SSENSE.COM</a>
          <p className="font-light text-gray-300">INNOVATION</p>
        </div>
        <p className="text-center text-3xl">SSENSE</p>
        <div className="items-center space-x-6 justify-end hidden md:flex">
          <a target='_blank' rel="noreferrer" href='https://apps.apple.com/app/ssense-shop-designer-fashion/id1418754101' className="hover:underline focus:underline font-light">APP STORE</a>
          <a target='_blank' rel="noreferrer" href='https://play.google.com/store/apps/details?id=ssense.android.prod' className="hover:underline focus:underline font-light">PLAY STORE</a>
        </div>
      </div>
      <div className="fixed top-0 left-0 md:hidden mx-3 mt-4">
        <button onClick={togglePanel} className="mr-2">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 30 30"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeWidth="1" d="M5 5h20M5 15h20M5 25h20" />
          </svg>
        </button>
      </div>
      <div
        className={`fixed inset-0 bg-white transform transition-all duration-300 ease-in-out ${isOpen ? "opacity-100 translate-x-0" : "opacity-0 -translate-x-full"}`}
      >
        <div className="absolute top-0 right-0 p-4">
          <button onClick={togglePanel} className="font-light text-xs">
            CLOSE
          </button>
        </div>
        <div className="mt-20 ml-6 space-y-5 text-sm">
          <div>
            <a target='_blank' rel="noreferrer" href='https://www.ssense.com/' className="hover:underline focus:underline font-light"><p>SSENSE.COM</p></a>
          </div>
          <div>
            <p className="font-light text-gray-300">INNOVATION</p>
          </div>
          <div>
            <a target='_blank' rel="noreferrer" href='https://apps.apple.com/app/ssense-shop-designer-fashion/id1418754101' className="hover:underline focus:underline font-light"><p>APP STORE</p></a>
          </div>
          <div>
            <a target='_blank' rel="noreferrer" href='https://play.google.com/store/apps/details?id=ssense.android.prod' className="hover:underline focus:underline font-light"><p>PLAY STORE</p></a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
